<template>
  <b-card class="provider-services">
    <b-modal
      id="create-dispute-modal"
      v-model="serviceHistoryProps.viewDisputeModal"
      :class="disputeObject ? 'height-auto' : 'hide-dispute-details-footer'"
      :title="$t('Dispute Details')"
      :ok-title="$t('Submit')"
      ok-only
      :no-close-on-backdrop="true"
      @hidden="hideDisputeModal"
      @ok="submitServiceDispute"
    >
      <b-col
        v-if="!disputeObject"
        cols="12"
        md="12"
      >
        <b-form-group
          :label="$t('Dispute Name')"
          class="price-range-dropdown"
        >
          <v-select
            v-model="serviceHistoryProps.currentDispute"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="disputeOptions"
            :reduce="val => val.value"
            :clearable="false"
          />
        </b-form-group>
        <b-form-group
          v-if="serviceHistoryProps.currentDispute === 'Others'"
          :label="$t('Dispute Reason')"
          label-for="disputeReason"
        >
          <b-form-input
            id="disputeReason"
            v-model="serviceHistoryProps.disputeReason"
            type="text"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="12"
      >
        <p class="mb-50">
          <span class="font-weight-bold">{{ $t('Dispute Name') }}:</span> {{ serviceHistoryProps.disputeObject.dispute_name }}
        </p>
        <p class="mb-50">
          <span class="font-weight-bold">{{ $t('Date') }}:</span> {{ serviceHistoryProps.disputeObject.created_time }}
        </p>
        <p class="mb-50">
          <span class="font-weight-bold">{{ $t('Status') }}:</span> {{ serviceHistoryProps.disputeObject.status }}
        </p>
        <p class="mb-50">
          <span class="font-weight-bold">{{ $t('Comments By') }}:</span> {{ serviceHistoryProps.disputeObject.comments_by }}
        </p>
        <p class="mb-50">
          <span class="font-weight-bold">{{ $t('Comments') }}:</span> {{ serviceHistoryProps.disputeObject.comments }}
        </p>
      </b-col>
    </b-modal>
    <b-modal
      id="upcomingServiceModal"
      v-model="serviceHistoryProps.upcomingServiceDetailVisible"
      :no-close-on-backdrop="true"
      :title="$t('Service Details')"
      :hide-footer="true"
      size="lg"
      @hidden="hideUpcomingServiceModal"
    >
      <view-service
        v-if="serviceHistoryProps.tripHistoryObject"
        :service-history-props="{ tripHistoryObject: serviceHistoryProps.tripHistoryObject }"
      />
    </b-modal>
    <b-row>
      <b-col
        md="4"
      >
        <b-form-group :label="$t('Filters')">
          <v-select
            v-model="extraFilterInput"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="orderFilterOptions"
            :reduce="val => val.value"
            :clearable="false"
          />
        </b-form-group>
      </b-col>
      <b-col cols="12" :key="`extraFilterInput_${extraFilterInput}`">
        <good-data-table
          ref="upcomingServiceRef"
          :columns="serviceColumns"
          :total-column="'response.responseData.service.total'"
          :data-column="'response.responseData.service.data'"
          :export-map="'res.responseData.service'"
          :api-endpoint="'/provider/history/service'"
          :no-serial="true"
          :extra-params="`type=${extraFilterInput}`"
        />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import GoodDataTable from '@/@core/layouts/shared/GoodDataTable.vue'
import vSelect from 'vue-select'
import { hideLoader, showDangerNotification, showErrorNotification, showLoader, showSuccessNotification } from '@/@core/comp-functions/ui/app'
import { useProviderUi } from './useProvider'
import ViewService from './my-services/ViewService.vue'
import { getStatusTextForRequest, isEmpty } from '@/utilities'
export default {
  components: {
    GoodDataTable,
    vSelect,
    ViewService,
  },
  data() {
    return {
      extraFilterInput: 'all',
      serviceHistoryProps: {
        viewDisputeModal: false,
        currentDispute: '',
        disputeReason: '',
        currentServiceForDispute: {},
        disputeObject: {},
        tripHistoryObject: {},
        upcomingServiceDetailVisible: false,
        historyerviceDetailVisible: false,
        acceptRequestMessage: '',
      },
      disputeOptions: [
        {
          label: 'Select',
          value: '',
        },
        {
          label: 'Customer denied to pay amount',
          value: 'Customer denied to pay amount',
        },
        {
          label: 'User provided wrong service information',
          value: 'User provided wrong service information',
        },
        {
          label: 'User neglected to pay additional charge',
          value: 'User neglected to pay additional charge',
        },
        {
          label: 'User provided less amount',
          value: 'User provided less amount',
        },
        {
          label: 'Others',
          value: 'Others',
        },
      ],
      orderFilterOptions: [
        {
          label: 'All',
          value: 'all',
        },
        {
          label: 'Ongoing',
          value: 'ongoing',
        },
        {
          label: 'Completed',
          value: 'completed',
        },
        {
          label: 'Cancelled',
          value: 'cancelled',
        },
        {
          label: 'Upcoming',
          value: 'upcoming',
        },
      ],
      serviceColumns: [
        {
          label: 'Booking Id',
          field: 'booking_id',
        },
        {
          label: 'Date',
          field: 'created_time',
        },
        {
          label: 'Category',
          field: 'service_name',
          rField: 'service_name_text',
          useResolver: true,
          useRenderer: true,
          renderer: props => {
            if(!isEmpty(props.title)) return props.title;
            if (props.service && props.service.service_name) {
              return props.service.service_name
            }
            return 'NA'
          },
        },
        {
          label: 'Total Amount',
          field: 'total',
          rField: 'total_text',
          useResolver: true,
          useRenderer: true,
          renderer: props => this.getServicePrice(props),
        },
        {
          label: 'Status',
          field: 'status',
          align: 'center',
          useRenderer: true,
          useResolver: true,
          renderer: props => getStatusTextForRequest(props.status),
        },
        {
          label: 'Action',
          field: 'action',
          sortable: false,
          type: 'dropdown',
          actions: [
            {
              isIcon: true,
              text: 'Track Order',
              iconHtml: this.$helpers.getIcons('tracking'),
              action: props => {
                this.$router.push(`/provider/service/agent/view/${props.id}`)
              },
            },
            {
              isIcon: true,
              iconHtml: this.$helpers.getIcons('view'),
              text: 'View',
              action: props => {
                this.viewServiceHistory(props)
              },
            },
            {
              isIcon: 'download-invoice',
              text: 'Download Invoice',
              iconHtml: require('@/assets/images/app-icons/document.png'),
              action: props => {
                console.log(props, 'propsprops');
                if (props.payment && props.payment.invoice_url) {
                  window.open(window.SuperApp.helper.image(props.payment.invoice_url))
                }
              },
            },
          ],
        },
      ],
    }
  },
  computed: {
    disputeObject() {
      return this.serviceHistoryProps.disputeObject && this.serviceHistoryProps.disputeObject.id
    },
  },
  watch: {
    extraFilterInput: {
      handler() {
        this.$refs.upcomingServiceRef.loadItems()
      },
      immediate: true
    },
  },
  methods: {
    isEmpty,
    getServicePrice(props) {
      if (props.payment) {
        const providerCurrency = props.items && props.items[0] && props.items[0].provider ? props.items[0].provider.currency_symbol : ''
        return `${props.payment.payable} ${providerCurrency}`
      }
      return 'N/A'
    },
    hideUpcomingServiceModal() {
      this.serviceHistoryProps.upcomingServiceDetailVisible = false
    },
    viewServiceHistory(props) {
      this.serviceHistoryProps.currentServiceForDispute = props
      this.serviceHistoryProps.upcomingServiceDetailVisible = true
      this.getTripHistory()
    },
    hideDisputeModal() {
      this.serviceHistoryProps.viewDisputeModal = false
    },
    createServiceDispute(props) {
      this.serviceHistoryProps.currentServiceForDispute = props
      this.serviceHistoryProps.viewDisputeModal = true
      this.getDisputeStatus()
    },
    submitServiceDispute() {
      if (this.disputeObject) return
      const formData = new FormData()
      formData.append('referral_email', '')
      formData.append('dispute_type', 'provider')
      formData.append('dispute_name', this.serviceHistoryProps.currentDispute)
      formData.append('comments', this.serviceHistoryProps.currentDispute === 'Others' ? this.serviceHistoryProps.disputeReason : '')
      formData.append('id', this.serviceHistoryProps.currentServiceForDispute.id)
      formData.append('user_id', this.serviceHistoryProps.currentServiceForDispute.user_id)
      formData.append('provider_id', this.serviceHistoryProps.currentServiceForDispute.provider_id)

      const { createServiceDispute } = useProviderUi()
      showLoader()
      createServiceDispute(formData)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            showSuccessNotification(this, data.message)
          } else {
            showDangerNotification(this, data.message)
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    getTripHistory() {
      showLoader()
      this.serviceHistoryProps.tripHistoryObject = {}
      const { providerTripServiceHistory } = useProviderUi()
      providerTripServiceHistory(this.serviceHistoryProps.currentServiceForDispute.id)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.serviceHistoryProps.tripHistoryObject = data.responseData.service
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    getDisputeStatus() {
      showLoader()
      this.serviceHistoryProps.disputeObject = {}
      const { providerDisputeStatus } = useProviderUi()
      providerDisputeStatus(this.serviceHistoryProps.currentServiceForDispute.id)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.serviceHistoryProps.disputeObject = data.responseData
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
  },
  mounted(){
    this.$refs.upcomingServiceRef.loadItems()
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-select.scss';

.vs__dropdown-toggle {
  border: 1px solid #d8d6de;
}
</style>

<style>
#create-dispute-modal .modal-body {
  min-height: 220px;
}

#create-dispute-modal.height-auto .modal-body {
  height: auto;
}
.order-filters-position {
  position: absolute;
  z-index: 9;
  left: 150px;
  top: 20px;
}
@media only screen and (max-width: 767px) {
  .order-filters-position {
    position: static;
  }
}
</style>
